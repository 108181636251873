<template>
	<div class="mainTem">
		<div class="buyerHeader">
			<h3 class="buyerHeaderTit">{{$t('i18nn_f77f473d27f48bdc')}}</h3>
		</div>
		<!-- <div style="text-align: center; margin-top: 10px;">
      <el-button-group>
        <el-button type="primary"  size="medium" style="width: 200px;">{{$t('i18nn_5b14580d813b4a45')}}</el-button>
        <el-button type="primary" plain size="medium" style="width: 200px;" @click="toPageUrl('PartnerBillSettlementDet')">{{$t('i18nn_1b613e926a1e65dd')}}</el-button>
      </el-button-group>
    </div> -->
		
		<div class="wh-filter-con">
			<div class="">
			  <span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="serPageData()">
				  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div>
		
		<!-- <div class="wh-filter-con">
			
			
			<div class="wh-filter-item">
				<span>{{ $t('Storage.packing_list.status') }}</span>
				<el-select filterable clearable v-model="filterData.status" :placeholder="$t('FormMsg.Please_select')" size="small" style="width: 100px;" @change="serPageData()">
					<el-option v-for="item in selectOption.statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<div class="wh-action-con">
			<el-button type="success" @click="openDioalog(null, '设置')" size="small" icon="el-icon-plus">{{$t('i18nn_556c0672dcecba13')}}</el-button>
			<!-- <el-button type="warning" @click="openExcelDialog" size="small" icon="el-icon-upload">{{$t('Storage.packing_list.Import')}}</el-button> -->
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small" :max-height="$store.state.frameConHeightWh">
				<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
				<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

			

				<el-table-column prop="proxyStatusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.proxyStatus">{{ scope.row.proxyStatusName }}</el-tag>
						<el-tag type="success" v-else-if="'10' == scope.row.proxyStatus">{{ scope.row.proxyStatusName }}</el-tag>
						<el-tag type="warning" v-else-if="'20' == scope.row.proxyStatus">{{ scope.row.proxyStatusName }}</el-tag>
						<el-tag type="danger" v-else-if="'90'==scope.row.proxyStatus">{{scope.row.proxyStatusName}}</el-tag>
						<el-tag type="info" v-else>{{ scope.row.proxyStatusName }}</el-tag>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="mobile" :label="$t('i18nn_b00576fed9b3a1f2')">
					<template slot-scope="scope">
						{{ scope.row.mobile }}
					</template>
				</el-table-column> -->

				<el-table-column prop="cusWhName" :label="$t('i18nn_29991afa9781d554')"></el-table-column>

				<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->

			

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="300px" fixed="right" align="left">
					<template slot-scope="scope">
						<!-- <div v-if="isSel"><el-button @click="selRow($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-magic-stick">{{$t("FormMsg.Select")}}</el-button></div> -->
						<!-- <div v-else> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->
						<!-- <el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t("FormMsg.Delete")}}</el-button> -->
						<!-- </div> -->
						<!-- <el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini" icon="el-icon-edit">{{$t("FormMsg.Edit")}}</el-button> -->

						<div style="margin-bottom: 10px;">
							<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{$t('i18nn_6ede9303c2d93ede')}}</el-button>
					  </div>
						

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>
		<!-- 修改弹窗 -->
		<el-dialog :close-on-click-modal="false"  :title="dialogFormMsg" :visible.sync="dialogFormVisible" custom-class="myFullDialog3">
			<el-form ref="form" size="small" :rules="formRules" :model="form" label-width="150px" style="width: 1100px;" v-loading="loading" element-loading-text="正在提交">
				<!-- form:{
	      "id":'',//"数据ID",
	      "title":'',//"标题",
	      "orderType":'',//"工单类型",
	      "contentStr":'',//"工单内容",
	      "attachments":[
	          {
	              "id":'',//"数据ID",
	              "url":'',//"文件URL",
	              "fileName":'',//"文件名称"
	          }
	      ]
	  }, -->

				<!-- <h3 class="titSty1">{{ $t('Storage.packing_list.basic_information') }}</h3> -->
				<el-form-item :label="'账号(手机号)'" prop="">
					<el-input type="text" v-model="form.mobile" show-word-limit :placeholder="$t('FormMsg.Please_Enter')" style="width: 300px;"></el-input>
				</el-form-item>
				
			
			</el-form>

			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button>
				<el-button type="primary" @click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button>
			</div>
		</el-dialog>



		<!--查看明细-->
		<!-- <el-dialog :title="$t('FormMsg.details_View')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="0">
			<div style=""><workOrderDet :id="packId"></workOrderDet></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

	
	</div>
</template>
<script>
// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
export default {
	// name: 'BuyerOrder',
	//meta信息seo用
	// metaInfo: {
	//   title: '互易天下-厂家共享平台-买家中心-收货账单', // set a title
	//   meta: [{ // set meta
	//     name: '互易天下-买家中心-收货账单',
	//     content: '互易天下-厂家共享平台-买家中心-收货账单'
	//   }],
	//   // link: [{                 // set link
	//   //   rel: 'asstes',
	//   //   href: 'https://assets-cdn.github.com/'
	//   // }]
	// },
	// props: ['isDialog'],
	// props: {
	//   // mobile:"",
	//   isSel: {
	//     default: function() {
	//       return false;
	//     },
	//     type: Boolean
	//   },
	//   status: {
	//     default: function() {
	//       return '';
	//     },
	//     type: String
	//   }
	// },
	components: {
		// SelAgentUser
	},
	data() {
		return {
			// dialogShowDet: false,
			// packId: null,

			// isShowFileUpload: false,
			// fileRelationId: '',
			// FileUploadOpenTime: '',
			// activeName: 'second',
			
			//打开入库选择框
			// dialogSelVisible: false,
			// openRowData: {},
			// openRowIndex: {},

			UserInfo: this.$store.getters.getUserInfo,
			dialogFormVisible: false,
			dialogFormStatus: 0, //0-新增，1-修改
			dialogFormMsg: '',

			//导入excel
			// dialogUploadVisible: false,
			// loadingExcel: false,
			// fileExcel: '',
			// excelData: [],
			// excelFileName: '',

			//图片放大
			// dialogImgVisible: false,
			// imgUrlBigShow: '',

			loading: false,

			loading_det: false,

			//附件
			// fileWatchKey: '1',
			// fileList: [],
			// fileId: this.$store.getters.getUserInfo.id,

			form: {
				 "mobile":"",//"账号"
			},

			formRules: {
				mobile: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],

			},
			loading_load: false,
			tableData: [],
			//分页数据
			pagination: {
				current_page: 1,
				page_sizes: [5, 10, 20, 30],
				page_size: 10,
				total: 0
			},
			selectOption: {
				// wh_weight_unit: [],
				// wh_vol_unit: [],
				// wh_goods_fee_type: [],
				wh_work_order_type: [],
				// wh_packing_type: [],
				statusList: [
					{
						value: '',
						label: this.$t('Storage.packing_list.All')
					},
					{
					  value: '10',
					  label: '绑定'
					},
					{
					  value: '90',
					  label: '解绑'
					},
				]
			},
			//查询，排序方式
			filterData: {
				orderBy: 'id_', //排序字段
				sortAsc: 'desc', //desc降序，asc升序
				// "accountPeriod":"",
				// "billNo":""
				status: '',
				// packingNo: ''
				// putWhNo: '',
				// goodsSku: '',
				// hashCode: '',
				// goodsName: '',
				// declareNameCh: ''
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
	},
	//编译挂载前
	mounted() {
		// this.getDicData();

		this.initData();
	},
	methods: {
		initData() {
			this.pagination.current_page = 1;
			// this.currentSel = {};
			// this.multipleSelection = [];
			this.getPageData();
			// this.getDicData();
		},

		//跳转页面
		// toPageUrl(name){
		//   this.$router.push({'name':name});
		// },
		//打开新增编辑，弹窗
		openDioalog(formParm, msg) {
			// console.log(formParm);
			this.dialogFormVisible = true;
			let form = Object.assign({}, formParm);
			console.log('form', form);
			this.dialogFormMsg = msg;
			// // 重置
			this.resetForm('form');
			// if (null === formParm) {
				//新增

				// this.form.weightUnit = '1';
				// this.form.volumeUnit = '1';
				// this.form.whFeeType = '1';

				this.dialogFormStatus = 0;
				// form.subUserId = null;
				// form.userSubUserId = null;
				// form.state = true;
				//浅拷贝、对象属性的合并
				this.form = form;
			// } else {
			// 	//修改
			// 	this.dialogFormStatus = 1;
			// 	// form.state = form.state === '0' ? true : false;

			// 	//浅拷贝、对象属性的合并
			// 	this.form = form;
			// }

			this.$forceUpdate();
			// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			//       type: 'warning',
			//       //confirmButtonText: '确定',
			//     });
		},
		// 富文本
		// onEditorChange(html) {
		//   this.form.contentStr = html;
		// },
		//打开编辑
		// openEdit(event, row, index) {
		// 	event.stopPropagation();
		// 	this.openDioalog(row, this.$t('FormMsg.Edit'));
		// 	this.getPageDetData(row.id);
		// },
		//明细
		//请求分页数据
		// getPageDetData(id) {
		// 	// let _this = this;
		// 	this.loading_det = true;
		// 	// this.$http
		// 	//   .put(this.$urlConfig.WhPackingListDetPageList, {
		// 	//     // "sortAsc": this.filterData.sortAsc,
		// 	//     // "orderBy": this.filterData.orderBy,
		// 	//     offset: 0, //当前页
		// 	//     limit: 100, //当前页显示数目

		// 	//     packId: packId
		// 	//     // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
		// 	//     // goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
		// 	//     // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
		// 	//     // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
		// 	//     // declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
		// 	//   })
		// 	this.$http
		// 		.get(this.$urlConfig.WhWorkOrderDet + '/' + id, {})
		// 		.then(({ data }) => {
		// 			// console.log(this.$store.getters.getUserInfo);
		// 			console.log('分页，请求成功');
		// 			console.log(data);
		// 			// this.$vux.loading.hide();
		// 			this.loading_det = false;
		// 			//表格显示数据
		// 			this.form = data.data;

		// 			this.fileList = data.data.attachments.map(item => {
		// 				return {
		// 					id: item.id,
		// 					name: item.fileName,
		// 					url: item.url
		// 				};
		// 			});
		// 			this.fileWatchKey = new Date().getTime();
		// 			//当前数据总条数
		// 			// this.pagination.total = parseInt(data.total);
		// 			//当前页数
		// 			// this.pagination.current_page = parseInt(data.current);
		// 			//当前页条数
		// 			// this.pagination.page_size = parseInt(data.size);
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('分页，请求失败');
		// 			this.loading_det = false;
		// 		});
		// },

		
		//上传回调
		// changeFileUrl(val) {
		// 	console.log('changeFileUrl', val);
		// 	let listUrl = val.map(item => {
		// 		// "filePath":"","fileName"：“”
		// 		return {
		// 			url: item.url,
		// 			fileName: item.name
		// 		};
		// 	});
		// 	// console.log('listUrl', listUrl);
		// 	// this.$alert('上传成功！共上传 ' + listUrl.length + ' 个附件~', this.$t('tips.tipsTitle'), {
		// 	//   type: 'success'
		// 	//   //confirmButtonText: '确定',
		// 	// });
		// 	// this.listUrl = listUrl;
		// 	this.form.attachments = listUrl;
		// 	// this.myForm.dataList = listUrl;
		// 	// this.myForm.dataList = val;

		// 	// this.submitExcelFormAction();
		// 	// this.submitExcelFormData();
		// },

		//删除
		delAction(event, row) {
			event.stopPropagation();
			this.$confirm('确定解除绑定吗？', this.$t('tips.tipsTitle'), {
				// confirmButtonText: '确定',
				// cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					// this.$message({
					//   type: 'success',
					//   message: '删除成功!'
					// });
					this.delDataAction(event, row);
				})
				.catch(() => {
					// this.$message({
					//   type: 'info',
					//   message: '已取消删除'
					// });
				});
		},
		//删除
		delDataAction(event, row) {
			event.stopPropagation();
			console.log('delDataAction', row);
			// let parm = [];

			//   if (!!row) {
			//     //单条
			//     parm = [row.id];
			//   } else {
			//     //多条
			// //     let dataList = this.multipleSelection;
			// //     if (dataList.length < 1) {
			// //       this.$message.warning('请先选择要操作的数据');
			// //       return;
			// //     }

			// //     let dataListParm = dataList.map(v => v.id);
			// //     parm = dataListParm;
			//     // console.log('dataListParm', dataListParm);
			//     // let dataParm = {
			//     //   ids: dataListParm
			//     // };
			//   }

			// this.postData(this.$urlConfig.WhWorkOrderDel + '/' + row.id, {}, 'delete');
			
			this.postData(this.$urlConfig.agentUserUnbind, {id:row.id});
		},

		//发货
		// shipmentsAction(event, row) {
		//   this.$confirm('确定发货吗?', this.$t('tips.tipsTitle'), {
		//     // confirmButtonText: '确定',
		//     // cancelButtonText: '取消',
		//     type: 'warning'
		//   })
		//     .then(() => {
		//       // this.$message({
		//       //   type: 'success',
		//       //   message: '删除成功!'
		//       // });
		//       // this.delDataAction(event, row);
		//       this.postData(this.$urlConfig.WhPackingListShipments + '/' + row.id, {}, () => {
		//         // this.initData();
		//         //  this.$alert('恭喜,提交成功！', '提示信息', {
		//         //   type: 'success',
		//         //   confirmButtonText: '确定',
		//         // });
		//         this.$message.success(this.$t('tips.submitSuccess'));
		//       });
		//     })
		//     .catch(() => {
		//       // this.$message({
		//       //   type: 'info',
		//       //   message: '已取消删除'
		//       // });
		//     });
		// },

		//打开选择数据
		// openSelData(event, row, index) {
		//   event.stopPropagation();

		//   this.dialogSelVisible = true;
		//   this.openRowData = row;
		//   this.openRowIndex = index;
		//   // this.$nextTick(() => {
		//   //   this.$refs.hyUpLoadImg1.openFile();
		//   // });
		// },
		//选择数据后回调
		// selWhGoodsData(selRow) {
		//   this.dialogSelVisible = false;
		//   console.log('selRow', selRow);
		//   let row = this.openRowData;
		//   // row.putWhNo = selRow.putWhNo;
		//   // row.plPlaceNo = selRow.placeNo;
		//   row.goodsSku = selRow.goodsSku;
		//   row.goodsImg = selRow.goodsImg;
		//   row.goodsName = selRow.goodsName;
		//   // row.goodsId = selRow.id;

		//   // row.cusCode = selRow.cusCode;
		//   row.goodsWeight = selRow.goodsWeight;
		//   // row.weightUnit = selRow.weightUnit;
		//   row.goodsLength = selRow.goodsLength;
		//   row.goodsWidth = selRow.goodsWidth;
		//   row.goodsHeight = selRow.goodsHeight;
		//   row.sizeUnit = selRow.sizeUnit;

		//   row.safeStock = selRow.safeStock;

		//   // row.defCode = selRow.defCode;
		//   // row.hashCode = selRow.hashCode;
		//   // row.declareNameCh = selRow.declareNameCh;
		//   // row.declareNameEn = selRow.declareNameEn;
		//   row.goodsNameEn = selRow.goodsNameEn;
		//   row.declarePrice = selRow.declarePrice;

		//   // defCode: '', //"自定义编码",
		//   // hashCode: '', //"海关编码",
		//   // declareNameCh: '', //"申报中文名称",
		//   // declareNameEn: '', //"申报英文名称",
		//   // declarePrice: '', //"申报价值",

		//   this.$set(this.form.packRecordList, this.openRowIndex, row);

		//   //计算
		//   // this.CalculateTotalWeAndVo(row,this.openRowIndex);
		//   // this.openRowData = row;
		//   // this.openRowIndex = index;
		//   // this.$nextTick(() => {
		//   //   this.$refs.hyUpLoadImg1.openFile();
		//   // });
		// },

		// hyUpLoadImg1: function(childValue) {
		// 	// childValue就是子组件传过来的值
		// 	this.form.goodsImg = childValue;
		// },

		//查看图片
		// openBigImg(event, imgsrc) {
		// 	event.stopPropagation();
		// 	console.log('openBigImg', imgsrc);
		// 	this.dialogImgVisible = true;
		// 	this.imgUrlBigShow = imgsrc;
		// },

		//点击状态为非出账弹出窗
		// noConfirm(){
		//   this.$alert('仅"已出账"状态的数据进行"确认"操作，请核实！', this.$t('tips.tipsTitle'), {
		//         type: 'warning',
		//         //confirmButtonText: '确定',
		//       });
		// },
		//提交信息
		submitForm(formName) {
			// this.$nextTick(() => {
			// 	try {
			// 		this.$refs.HyElWhUpLoad.submitUpload();
			// 	} catch (err) {
			// 		console.log(err);
			// 	}
			// });

			this.$refs[formName].validate(valid => {
				if (valid) {
					let formData = Object.assign({}, this.form);
					//浅拷贝、对象属性的合并
					// if (0 === this.dialogFormStatus) {
						// formData.id = null;
						// formData.userId = this.UserInfo.id;

						this.postData(this.$urlConfig.agentUserAdd, formData);
					// } else {
					// 	formData.userId = this.UserInfo.id;
					// 	this.postData(this.$urlConfig.WhWorkOrderAdd, formData);
					// }
				} else {
					console.log('error submit!!');
					this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: '确定',
					});
					return false;
				}
			});
		},
		//重置输入框
		resetForm(formName) {
			console.log(formName);
			// console.log(this.$refs[formName]);

			this.$nextTick(() => {
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// if ('form' === formName) {
					// 	this[formName]['packRecordList'] = [];
					// }
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			});
			// this.$nextTick(() => {
			// 	try {
			// 		this.$refs.HyElWhUpLoad.initData();
			// 		this.$refs.HyElWhUpLoad.clearFileList();
			// 	} catch (err) {
			// 		console.log(err);
			// 	}
			// });
			// this.fileList = [];
		},

		//提交信息
		postData(url, formData, type) {
			// HttpTypelet _this = this;
			this.loading = true;
			this.loading_load = true;
			let HttpType = {};
			if ('delete' == type) {
				HttpType = this.$http.delete(url, formData);
			} else {
				HttpType = this.$http.put(url, formData);
			}
			HttpType.then(({ data }) => {
				console.log('提交成功');
				console.log(data);
				// this.$vux.loading.hide();
				this.loading = false;
				this.loading_load = false;
				if (200 == data.code) {
					this.dialogFormVisible = false;
					this.getPageData();
					
					if("delete"==type){
						this.$message({
						    type: 'success',
						    message: this.$t('tips.successfullyDelete')
						});
					} else {
						this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							type: 'success'
						});
					}
					
				} else {
					// if (!data.msg) {
					//   data.msg = this.$t('tips.submitError');
					// }
					this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: '确定',
					});
				}
			}).catch(error => {
				console.log(error);
				console.log(this.$t('tips.submitError'));
				this.loading = false;
				this.loading_load = false;
				this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
					type: 'warning'
					//confirmButtonText: '确定',
				});
			});
		},


		//查询数据
		serPageData() {
			this.pagination.current_page = 1;
			this.getPageData();
		},
		//请求分页数据
		getPageData() {
			// let _this = this;
			this.loading_load = true;
			this.$http
				.put(this.$urlConfig.agentUserPageList, {
					// sortAsc: this.filterData.sortAsc,
					// orderBy: this.filterData.orderBy,
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目

					proxyStatus: this.filterData.status ? this.filterData.status : null,
					// packingNo: this.filterData.packingNo ? this.filterData.packingNo : null
					// // putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				})
				.then(({ data }) => {
					console.log(this.$store.getters.getUserInfo);
					console.log('分页，请求成功');
					console.log(data);
					// this.$vux.loading.hide();
					this.loading_load = false;
					//表格显示数据
					this.tableData = data.rows;
					//当前数据总条数
					this.pagination.total = parseInt(data.total);
					//当前页数
					// this.pagination.current_page = parseInt(data.current);
					//当前页条数
					// this.pagination.page_size = parseInt(data.size);
				})
				.catch(error => {
					console.log(error);
					console.log('分页，请求失败');
					this.loading_load = false;
				});
		},

	
		//更新特定数据,
		// updatePageData(dataParm, msg) {
		//   // let _this = this;
		//   this.loading_load = true;
		//   //浅拷贝、对象属性的合并
		//   let dataParmAss = Object.assign({}, dataParm);
		//   // dataParmAss.isDefault = 1;
		//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
		//     .then(({ data }) => {
		//       console.log(msg + "特定数据，请求成功");
		//       console.log(data);
		//       if (200 == data.code) {
		//         this.$message.success(msg + '成功！');
		//         this.getPageData();
		//       } else {
		//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
		//       }
		//     })
		//     .catch((error) => {
		//       console.log(error);
		//       console.log("更新特定数据，请求失败");
		//       this.loading_load = false;
		//       this.$message.warning('' + msg + '失败,请重试！');
		//     });
		// },
		//查询数据字典
		// getDicData() {
		// 	// let _this = this;
		// 	// console.log(keyword);

		// 	// this.loading_load = true;
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_work_order_type'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
		// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
		// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
		// 				this.selectOption.wh_work_order_type = data.data['wh_work_order_type'];
		// 				// this.selectOption.wh_packing_type = data.data['wh_packing_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ .el-input-number,
.el-select {
	// width: 100px;
	.el-input__inner {
		text-align: left;
	}
}
.form_msg {
	color: #e6a23c;
}
</style>
